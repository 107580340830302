<template>
  <div class="app-container">
    <el-row>
      <div>{{ $t('title.orderinfo') }}</div>
      <el-divider />
    </el-row>
    <el-row>
      <h3>{{ $t("title.orderinfo") }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" disabled label-width="100px">
        <el-col :span="10">
          <el-form-item :label="$t('topic.PlatformOrderNumber')" prop="platFormName">
            <el-input v-model="creatermaForm.orderCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.platform')" prop="platFormName">
            <el-input v-model="creatermaForm.platFormName " disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.site')" prop="siteCode">
            <el-input v-model="creatermaForm.siteCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('title.shopName')" prop="shopCode">
            <el-input v-model="creatermaForm.shopCode" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.BuyerID')" prop="buyerUsername">
            <el-input v-model="creatermaForm.buyerUsername" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.ReturnWarehouse')" prop="buyerUsername">
            <el-input v-model="creatermaForm.shipperAddress" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.RMAstatus')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnStatusName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.RMAType')" prop="returnDetailType">
            <el-radio-group v-model="creatermaForm.returnDetailType" disabled>
              <el-radio label="0">{{ $t('topic.Refunds') }}</el-radio>
              <el-radio label="1">{{ $t('topic.Refundonly') }}</el-radio>
              <el-radio label="2">{{ $t('topic.Refundshipped') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="20">
          <el-form-item :label="$t('topic.RMAWay')" prop="returnType">
            <el-radio-group v-model="creatermaForm.returnType" disabled>
              <el-radio label="0" disabled>{{ $t('topic.System') }}</el-radio>
              <el-radio label="1">{{ $t('topic.Manual') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('page.createByName')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnCreateName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('page.createTime')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnCreateTime" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.Reviewer')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnReviewName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.Reviewtime')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnReviewTime" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.Refunder')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnPaymentName" disabled />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item :label="$t('topic.Refundtime')" prop="buyerUsername">
            <el-input v-model="creatermaForm.returnPaymentTime" disabled />
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Refundinformation') }}</h3>
      <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px">
        <el-col :span="20">
          <el-form-item :label="$t('topic.Typerefund')" prop="refundType">
            <el-radio-group v-model="creatermaForm.refundType" disabled>
              <el-radio v-if="creatermaForm.returnDetailType == '0'" label="0">{{ $t('topic.Agreeandrefund') }}</el-radio>
              <el-radio label="1">{{ $t('topic.Fullrefund') }}</el-radio>
              <el-radio label="2">{{ $t('topic.Partialrefund') }}</el-radio>
              <el-radio label="3">{{ $t('topic.Refuse') }}</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
    <el-row>
      <h3>{{ $t('title.Productinformation') }}</h3>
      <template>
        <el-table
          id="refund"
          :header-cell-style="{background:'#eee'}"
          :data="detailList.detailList"
          default-expand-all
          style="width: 100%"
        >
          <el-table-column
            :label="$t('title.Refundinformation')"
          >
            <template slot-scope="props">
              <div style="display:flex">
                <el-image
                  :src="props.row.mainImage"
                  style="width: 35px; height: 35px; vertical-align: middle"
                  class="mr-3"
                />
                <p style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap;">{{ props.row.sku }}</p>
              </div></template>
          </el-table-column>
          <el-table-column
            prop="returnNumber"
            :label="$t('topic.Numberofrefunds')"
            width="180"
          />
          <el-table-column
            :label="$t('topic.reasonforreturn')"
            width="160"
          >
            <template slot-scope="props">
              <el-select
                v-model="props.row.returnReasonCodeCN"
                clearable
                filterable
                disabled
                :placeholder="$t('page.selectPlaceholder')"
              >
                <el-option
                  v-for="item in returnReasonOptions"
                  :key="item.returnReasonCodeEN"
                  :label="language === 'en-US' ? item.returnReasonCodeEN : item.returnReasonCodeCN"
                  :value="item.returnReasonCodeCN"
                />
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            prop="trackingId"
            :label=" $t('title.Trackingforreturn')"
            width="180"
          />
          <el-table-column type="expand">
            <template slot-scope="props">
              <ul class="myTable">
                <li>
                  <span />
                  <span>{{ $t('title.orderamount') }}</span>
                  <span>{{ $t('topic.Orderrefund') }}</span>
                  <span />
                </li>
                <li v-if="props.row.itemPrice">
                  <span>{{ $t('topic.Productfee') }}</span>
                  <span>{{ props.row.itemPrice }}</span>
                  <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myItemPrice" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                  <span />
                </li>
                <li v-if="props.row.itemTax">
                  <span>{{ $t('topic.Extrashipping') }}</span>
                  <span>{{ props.row.itemTax }}</span>
                  <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myItemTax" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                  <span />
                </li>
                <li v-if="props.row.shippingPrice">
                  <span>{{ $t('topic.Extrashipping') }}</span>
                  <span>{{ props.row.shippingPrice }}</span>
                  <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myShippingPrice" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                  <span />
                </li>
                <li v-if="props.row.shippingTax">
                  <span>{{ $t('topic.Additionaltax') }}</span>
                  <span>{{ props.row.shippingTax }}</span>
                  <span style="display:flex;align-items: center;"><span> $ </span> <el-input v-model="props.row.myShippingTax" disabled oninput="value=value.replace(/[^0-9.]/g,'')" @change="changeNumber(props.$index)" /></span>
                  <span />
                </li>
                <li>
                  <span style="font-weight: 600;">{{ $t('title.Totalrefund') }}</span>
                  <span style="font-weight: 600;">{{ props.row.myrefundAmount }}</span>
                  <span style="font-weight: 600;">{{ props.row.refundAmount }}</span>
                  <span />
                </li>

              </ul>

            </template>
          </el-table-column>
        </el-table>
        <el-form ref="creatermaForm" :model="creatermaForm" label-width="100px" style="margin-top:10px">
          <el-col :span="15">
            <el-form-item :label="$t('title.Buyerpessage')">
              <el-input
                v-model="creatermaForm.sellComments"
                disabled
                type="textarea"
                :rows="2"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="15">
            <el-form-item :label="$t('title.stytemNote')">
              <el-input
                v-model="creatermaForm.remark"
                disabled
                type="textarea"
                :rows="2"
                :placeholder="$t('page.inputPlaceholder')"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('title.Buyerpicture')">
              <span v-for="item in sellerFileList" :key="item.id" style="width: 150px;height: 150px;display: block;">
                <img :src="item.url" alt="" style="width: auto;height: auto;max-width: 100%;max-height: 100%;">
              </span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item :label="$t('title.Attachpicture')">
              <span v-for="item in brcfilelist" :key="item.id" style="width: 150px;height: 150px;display: block;">
                <img :src="item.url" alt="" style="width: auto;height: auto;max-width: 100%;max-height: 100%;">
              </span>
            </el-form-item>
          </el-col>
        </el-form>
      </template>

    </el-row>
    <el-row :span="24" type="flex" style="margin-top: 20px" justify="center">
      <el-col :span="3">
        <el-button @click="goBack">{{ $t('title.back') }}</el-button>
      </el-col>
    </el-row>
  </div>

</template>
<script>
import Cookies from 'js-cookie'
import { getDetailReturnsInfoById, getAllReasonCode } from '@/api/omsorder'
import mixin from '@/mixin/oms-mixin'
export default {
  mixins: [mixin],
  data() {
    return {
      language: '',
      sellerFileList: [],
      brcfilelist: [],
      fordisabled: false,
      creatermaForm: {
        orderCode: '',
        siteCode: '',
        shopCode: '',
        platFormName: '',
        buyerUsername: '',
        shipperAddress: '',
        returnDetailType: '',
        returnType: '',
        refundType: ''

      },
      returnReasonOptions: [],
      detailList: []
    }
  },
  computed: {

  },
  mounted() {
    const lang = Cookies.get('language') === 'en' ? 'en-US' : 'zh-CN'
    const erplang = this.$store.getters.language && this.$store.getters.language === 'en' ? 'en-US' : 'zh-CN'
    this.language = erplang || lang
    this._getDetailReturnsInfoById()
  },
  methods: {
    async _getDetailReturnsInfoById(query) {
      const { id } = this.$route.query
      const { datas } = await getDetailReturnsInfoById(id)
      this.detailList = datas
      this.creatermaForm.orderCode = datas.orderCode
      this.creatermaForm.siteCode = datas.siteCode
      this.creatermaForm.shopCode = datas.shopCode
      this.creatermaForm.platFormName = datas.platFormName
      this.creatermaForm.buyerUsername = datas.buyerUsername
      this.creatermaForm.shipperAddress = datas.shipperAddress
      this.creatermaForm.returnDetailType = String(datas.returnDetailType)
      this.creatermaForm.returnType = String(datas.returnType)
      // this.creatermaForm.returnStatusName = datas.returnStatusName
      if (datas.returnStatus === 0) {
        this.creatermaForm.returnStatusName = this.$t('title.Awaitingapproval')
      } else if (datas.returnStatus === 1) {
        this.creatermaForm.returnStatusName = this.$t('title.Unshippeds')
      } else if (datas.returnStatus === 2) {
        this.creatermaForm.returnStatusName = this.$t('title.Unreceived')
      } else if (datas.returnStatus === 3) {
        this.creatermaForm.returnStatusName = this.$t('title.Received')
      } else if (datas.returnStatus === 20) {
        this.creatermaForm.returnStatusName = this.$t('title.Deliveredreceived')
      } else if (datas.returnStatus === 4) {
        this.creatermaForm.returnStatusName = this.$t('title.Refunded')
      } else if (datas.returnStatus === 5) {
        this.creatermaForm.returnStatusName = this.$t('title.Refused')
      } else if (datas.returnStatus === 6) {
        this.creatermaForm.returnStatusName = this.$t('title.Failedrefund')
      } else if (datas.returnStatus === 7) {
        this.creatermaForm.returnStatusName = this.$t('title.Canceled')
      } else if (datas.returnStatus === 8) {
        this.creatermaForm.returnStatusName = this.$t('title.draft')
      } else if (datas.returnStatus === 9) {
        this.creatermaForm.returnStatusName = this.$t('title.Partiallyreceived')
      } else if (datas.returnStatus === 10) {
        this.creatermaForm.returnStatusName = this.$t('title.Refunding')
      } else if (datas.returnStatus === 11) {
        this.creatermaForm.returnStatusName = this.$t('title.Transferring')
      } else if (datas.returnStatus === 12) {
        this.creatermaForm.returnStatusName = this.$t('topic.Partial')
      } else if (datas.returnStatus === 13) {
        this.creatermaForm.returnStatusName = this.$t('topic.refuses')
      } else if (datas.returnStatus === 15) {
        this.creatermaForm.returnStatusName = this.$t('title.Closed')
      } else {
        this.creatermaForm.returnStatusName = ''
      }
      this.creatermaForm.returnCreateTime = datas.returnCreateTime
      this.creatermaForm.returnReviewTime = datas.returnReviewTime
      this.creatermaForm.returnPaymentTime = datas.returnPaymentTime
      this.creatermaForm.returnCreateName = datas.returnCreateName
      this.creatermaForm.returnReviewName = datas.returnReviewName
      this.creatermaForm.returnPaymentName = datas.returnPaymentName
      this.creatermaForm.remark = datas.remark
      this.creatermaForm.sellComments = datas.sellComments
      datas.detailList.map(e => {
        e.myrefundAmount = (parseFloat(e.itemPrice) + parseFloat(e.itemTax) + parseFloat(e.shippingPrice) + parseFloat(e.shippingTax)).toFixed(2)
        e.refundAmount = (parseFloat(e.myItemPrice) + parseFloat(e.myItemTax) + parseFloat(e.myShippingPrice) + parseFloat(e.myShippingTax)).toFixed(2)
        console.log(e.refundAmount)
        this.creatermaForm.refundType = String(e.refundType)
      })
      datas.imageList.map(e => {
        if (e.imageType === 0) {
          const index = e.imageUrl.lastIndexOf('\/')
          const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
          this.sellerFileList.push({
            name: fileName,
            url: e.imageUrl
          })
        } else {
          const index = e.imageUrl.lastIndexOf('\/')
          const fileName = e.imageUrl.substring(index + 1, e.imageUrl.length)
          this.brcfilelist.push({
            name: fileName,
            url: e.imageUrl
          })
        }
        console.log(this.sellerFileList)
        console.log(this.brcfilelist)
      })
      this._getAllReasonCode(this.creatermaForm.platFormName)
    },
    // 获退款退货原因
    async _getAllReasonCode(platform) {
      const { datas } = await getAllReasonCode(platform)
      this.returnReasonOptions = datas
    },
    goBack() {
      this.$router.push({
        path: '../../ebay-return-manage',
        append: true,
        query: { ...this.userInfo }
      })
    }
  }
}
</script>
<style lang="scss" scope>
.float-right {
  display: flex;
  justify-content: flex-end;
}
#refund /deep/ .el-table__expand-column{
  pointer-events: none;
}
#refund /deep/ .el-table__expand-column .el-icon{
  visibility:hidden;
}
.myTable {
  width: 100%;
  border: 1px solid #dfe6ec;
  padding: 0;
  text-align: center;
}
.myTable li {
  width: 100%;
  display: flex;
  list-style: none;
  padding: 5px 0;
  align-items: center;
}
.myTable li:nth-of-type(1) {
  border-bottom: 1px solid #dfe6ec;
  background: #eee;
}
.myTable li:nth-of-type(n-1) {
  border-bottom: 1px solid #dfe6ec;
}
.myTable li  span {
  flex: 1;
}
/deep/ .input-style{
  width: 200px;
}
#refund {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  border-top: 1px solid #eee;
}
</style>
